<template>
  <ion-page>
    <div class="content">
      <iframe src="https://xat.com/embed/chat.php#id=35395826&gn=DistractieOnline" width="100%" height="100%"></iframe>
    </div>
  </ion-page>
</template>

<script>
import {IonPage} from '@ionic/vue';

export default {
  components: {
    IonPage
  },
};
</script>

<style scoped>
.ion-page {
  background-color: #3880ff;
}

.content {
  height: 100%;
}
.content iframe{
  /*min-height: 460px;*/
}
</style>
